.image-gallery {
    background-color: black;
    border-radius: 10px;
    overflow: hidden;
}

.image-gallery-bullets .image-gallery-bullet {
    background: #edeff6;
    border-color: #edeff6;

    &.active {
        background: #2255ff;
        border-color: #2255ff;
    }
}

.image-gallery-slide .image-gallery-description {
    position: absolute;
    z-index: 10;
    font-size: 22px;
    font-weight: bold;
    bottom: 60px;
    right: 20px;
    text-align: right;
    max-width: 85%;
    background: none;
    opacity: 1;
}
